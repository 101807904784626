"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-serverview"
  }, [_c('div', {
    staticClass: "kiwi-serverview-inner"
  }, [_c('tabbed-view', {
    key: _vm.network.id,
    ref: "tabs",
    on: {
      "changed": _vm.tabChanged
    }
  }, [_c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('messages'),
      "focus": _vm.hasMessages,
      "name": "messages"
    }
  }, [_c('message-list', {
    attrs: {
      "buffer": _vm.serverBuffer
    }
  })], 1), !_vm.restrictedServer ? _c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('settings'),
      "name": "settings"
    }
  }, [_c('network-settings', {
    attrs: {
      "network": _vm.network
    }
  })], 1) : _vm._e(), _vm.networkConnected ? _c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('channels'),
      "name": "channels",
      "focus": true
    }
  }, [_c('channel-list', {
    attrs: {
      "network": _vm.network
    }
  })], 1) : _vm._e(), _vm._l(_vm.pluginUiElements, function (item) {
    return _c('tabbed-tab', {
      key: item.id,
      attrs: {
        "header": item.title(),
        "name": item.tabName
      }
    }, [_c(item.component, _vm._b({
      tag: "component",
      attrs: {
        "network": _vm.network
      }
    }, 'component', item.props, false))], 1);
  })], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;