"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _xhr = _interopRequireDefault(require("xhr"));
var _queryString = _interopRequireDefault(require("query-string"));
var apiBaseUrl = 'https://api.chateagratis.net';
var apiService = {
  getToken: function getToken(params) {
    return new _promise.default(function (resolve, reject) {
      _xhr.default.get("".concat(apiBaseUrl, "/oauth/v2/token?").concat(_queryString.default.stringify(params)), {
        json: true
      }, function (err, res) {
        if (err) {
          reject(err);
        } else if (res.statusCode !== 200) {
          reject(res.body);
        } else {
          resolve(res.body);
        }
      });
    });
  },
  auth: function auth(user, password) {
    var queryParams = {
      client_id: '24_2oldq1z88um8k8g4ksk4w088ws0skgkg4o8o8gockkg408ss04',
      client_secret: '1cobscjcltxc48ggs4g84c8ckowco08gs8s4o84wgc884coggo',
      grant_type: 'password',
      username: user,
      password: password
    };
    return this.getToken(queryParams);
  },
  guest: function guest() {
    var queryParams = {
      client_id: '35_4vmbm7eqrpgk88oc0c00g8g88wkwkow4sg00o4og4kwowgkkks',
      client_secret: '3hfz1ebez9og4wko8s0wgo88kssss8okgw40o4go8o0wkwg0wg',
      grant_type: 'guest_credentials'
    };
    return this.getToken(queryParams);
  }
};
var _default = apiService;
exports.default = _default;